<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">学员统计</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学习详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div style="margin:0 14px 20px">
          <span>姓名：{{userName}}</span>
          <span style="margin:0 40px;">身份证号：{{idcard}}</span>
          <span style="margin:0 20px;">电话：{{mobile}}</span>
          <span style="margin:0 20px;">课程名称：{{courseName}}</span>
        </div>
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox">
            <div title="课节名称" class="searchboxItem ci-full">
              <span class="itemLabel">课节名称:</span>
              <el-input
                v-model="catalogName"
                type="text"
                size="small"
                placeholder="请输入课节名称"
                clearable
              />
            </div>
            <div title="学习状态" class="searchboxItem ci-full">
              <span class="itemLabel">学习状态:</span>
              <el-select v-model="complateState" clearable placeholder="请选择" size="small">
                <el-option
                  v-for="item in complateStatelist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="课后习题" class="searchboxItem ci-full">
              <span class="itemLabel">课后习题:</span>
              <el-select v-model="paperState" clearable placeholder="请选择" size="small">
                <el-option
                  v-for="item in paperStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="学习进度" class="searchboxItem ci-full">
              <span class="itemLabel">学习进度:</span>
              <el-input
                v-model="studyProgressStart"
                @keyup.native="studyProgressStart = zF.positiveInteger(studyProgressStart)"
                placeholder="请输入"
                clearable
                size="small"
                class="sjd"
              ></el-input>
              <span style="min-width: 2rem !important">%</span>
              <span style="min-width: 2rem !important">至</span>
              <el-input
                v-model="studyProgressEnd"
                @keyup.native="studyProgressEnd  = zF.positiveInteger(studyProgressEnd)"
                placeholder="请输入"
                size="small"
                class="sjd"
                clearable
              ></el-input>
              <span style="min-width: 2rem !important">%</span>
            </div>

            <!-- <div title="学习状态" class="searchboxItem ci-full">
              <span class="itemLabel">学习状态:</span>
              <el-input
                v-model="complateState"
                type="text"
                size="small"
                placeholder="请输入学习状态"
                clearable
              />
            </div>-->
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100"
                fixed
                :index="indexMethod"
              />

              <el-table-column
                label="课节名称"
                align="left"
                width="180"
                show-overflow-tooltip
                prop="catalogName"
              />
              <el-table-column
                label="视频总时长"
                align="right"
                width="100"
                show-overflow-tooltip
                prop="kpointDuration"
              >
                <template slot-scope="scope">
                  <span>{{getTime(scope.row.kpointDuration)}}</span>
                </template>
              </el-table-column>
              <!-- <el-table-column
                label="课节学时"
                align="right"
                show-overflow-tooltip
                prop="kpointLessonNum"
                width="100px"
              /> -->
              <el-table-column
                label="学习状态"
                align="center"
                show-overflow-tooltip
                prop="completeState"
                width="100px"
              >
                <template
                  slot-scope="scope"
                >{{$setDictionary("LEARNINGSTATE", scope.row.completeState)}}</template>
              </el-table-column>
             
              <el-table-column
                v-if="type!='10'"
                label="开始学习时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                width="180px"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.createTime">{{scope.row.createTime | moment}}</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <!-- 学习轨迹时间 -->
              <el-table-column
                v-else
                label="开始学习时间"
                align="left"
                show-overflow-tooltip
                prop="studyStartTime"
                width="150px"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.studyStartTime">{{scope.row.studyStartTime | moment}}</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column label="完成时间" align="left" show-overflow-tooltip >
                <template slot-scope="scope">
                  <span v-if="scope.row.completeTime">{{scope.row.completeTime | moment}}</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              
              <el-table-column label="学习进度" align="left" show-overflow-tooltip >
                <template slot-scope="scope">
                  <!-- <span v-if="scope.row.schedule">{{scope.row.schedule+'%'}}</span> -->
                  <span v-if="scope.row.schedule>=0||scope.row.schedule<=100">{{scope.row.schedule+'%'}}</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <!-- <el-table-column
                label="最后观看时间"
                align="left"
                show-overflow-tooltip
                prop="lastTime"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.lastTime">{{scope.row.lastTime | moment}}</span>
                  <span v-else>--</span>
                </template>
              </el-table-column> -->
               <!-- <el-table-column
                v-if="type=='10'"
                label="创建时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.createTime">{{scope.row.createTime | moment}}</span>
                  <span v-else>--</span>
                </template>
              </el-table-column> -->
              <el-table-column label="课后习题" align="center" show-overflow-tooltip prop="paperStatus"></el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
export default {
  name: "operate/learningDetails",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      catalogName: "",
      projectName: "",
      courseName: "",
      idcard: "",
      mobile: "",
      userName: "",
      CompanyList: [],
      requireLessonSum: "",
      studyLessonSum: "",
      complateState: "",
      studyProgressStart: "",//学习进度开始
      studyProgressEnd:"",//学习进度结束
      tableData: "",
      complateStatelist: [],
      paperState: "",
      paperStatusList: [
        { value: null, label: "全部" },
        { value: 10, label: "未配置" },
        { value: 20, label: "未考试" },
        { value: 30, label: "未通过" },
        { value: 40, label: "已通过" }
      ],
      type: this.$route.query?.type ?? '', // 上海类型班级 10
    };
  },
  created() {
    this.getLearningstateList();
    this.getTableHeight();
    this.getData();
  },
  computed: {},
  methods: {
    getData(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        complateState: this.complateState,
        paperState: this.paperState,
        userId: this.$route.query.userId,
        projectCourseId: this.$route.query.projectCourseId,
        catalogName:this.catalogName,
      };
      if ((this.studyProgressStart && !this.studyProgressEnd) || (!this.studyProgressStart && this.studyProgressEnd)) {
        this.$message.warning("请补全学习进度区间");
        return false;
      } 
      if (this.studyProgressStart && this.studyProgressEnd) {
        if(Number(this.studyProgressStart)<0) {
          this.$message.warning("开始学习进度不能小于0");
          return false;
        }else if (Number(this.studyProgressStart)>100) {
          this.$message.warning("开始学习进度不能大于100");
          return false;
        }else if ( Number(this.studyProgressEnd)<0) {
          this.$message.warning("结束学习进度不能小于0");
          return false;
        }else if ( Number(this.studyProgressEnd)>100) {
          this.$message.warning("结束学习进度不能大于100");
          return false;
        }else if (Number(this.studyProgressStart) > Number(this.studyProgressEnd) ) {
          this.$message.warning("开始学习进度不能大于结束学习进度");
          return false;
        }else {
          params.startSchedule = this.studyProgressStart;
          params.endSchedule = this.studyProgressEnd;
        }
      }
      let url = '/biz/report/user/page-View-list'
      if(this.$route.query.type == '10') {
        url = 'biz/report/user/pageList/sh'
      }
      this.doFetch({
        url: url,
        params, 
        pageNum
      });
      this.getListInfo({
        url: "/biz/report/user/userInfo",
        params: params
      });
    },
    getListInfo(params) {
      this.$post(params.url, params.params).then(res => {
        if (res.status == "0") {
          const data = res.data || {};
          this.userName = data.userName;
          this.mobile = data.mobile;
          this.idcard = data.idcard;
          this.courseName = data.courseName;
        }
        console.log(res.data)
      });
    },
    getLearningstateList() {
      const complateStatelist = this.$setDictionary("LEARNINGSTATE", "list");
      const list = [];
      for (const key in complateStatelist) {
        if(key!="40"){
        list.push({
          value: key,
          label: complateStatelist[key]
        });
        }
       
      }

      this.complateStatelist = list;
    },

    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.5) * 16;
      if (opDom) {
        tHeight -=  40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  }
};
</script>
<style lang="less" scope>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.sjd {
  width: 5.2rem;
  input {
    border: none;
    border-bottom: 1px solid #dcdfe6;
    border-radius: 0;
  }
}
</style>
